<script lang="ts">
	import { waitForDebugger } from 'inspector';
	import { tick } from 'svelte';
	import { onMount } from 'svelte';
	import { beforeUpdate, afterUpdate } from 'svelte';
	import Modal from './Modal.svelte';
	import Card from "./Card.svelte";
	import Image from "./Image.svelte";
	import { tweened } from 'svelte/motion';
	import { cubicOut } from 'svelte/easing';
	import { onDestroy } from 'svelte';

	function onInterval(callback, milliseconds) {
		const interval = setInterval(callback, milliseconds);

		onDestroy(() => {
			clearInterval(interval);
		});
	}

	const layers = [0, 1, 2, 3, 4, 5, 6, 7, 8];

	let y;

	let provar = 0;

	const progress = tweened(0, {
		duration: 1050,
		easing: cubicOut
	});

	progress.set(1);

	// onInterval(() => { 
	// 	if (provar < 100)
	// 	{
	// 		provar += 1;
	// 		progress.set(provar/100);
	// 		console.log(provar);
	// 	}
	// }, 50);
</script>

<svelte:window bind:scrollY={y}/>



<nav>
	<div class="title">
		<h1>
			Acinonyx
		</h1>
	</div>
	<div class="box">
		<a href="https://blog.rime.red">Blog</a>
	</div>
</nav>

<main class='fs'>
	<div class="title">
		<h1>
			Acinonyx
		</h1>
		<progress value={$progress}></progress>
	</div>
</main>

<!-- <a class="parallax-container" href="https://www.firewatchgame.com">
	{#each [0, 1, 2, 3, 4, 5, 6, 7, 8] as layer}
		<img
			style="transform: translate(0,{-y * layer / (layers.length - 1)}px)"
			src="https://www.firewatchgame.com/images/parallax/parallax{layer}.png"
			alt="parallax layer {layer}"
		>
	{/each}
</a>

<div class="text">
	<span style="opacity: {1 - Math.max(0, y / 40)}">
		scroll down
	</span>

	<div class="foreground">
		You have scrolled {y} pixels
	</div>
</div> -->


<style>
	.fs {
		height:100vh;
	}

	.fs .title
	{
		display:block;
		text-align: center;
	}

	.fs .title h1
	{
		font-size: 1.75em;
		color: #ff3e00;
		font-weight: 100;
		font-family: 'Flow Circular',  sans-serif;
		font-display: swap;
		margin-left: 0;
		margin-top: calc(50vh - 27px);
	}

	.parallax-container {
		position: fixed;
		width: 2400px;
		z-index: 0;
		height: 712px;
		left: 50%;
		transform: translate(-50%,0);
	}

	.parallax-container img {
		position: absolute;
		/* margin-top: 54px; */
		top: 0;
		left: 0;
		width: 100%;
		will-change: transform;
	}

	.parallax-container img:last-child::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgb(45,10,13);
	}

	.foreground {
		position: absolute;
		top: 711px;
		left: 0;
		width: 100%;
		height: calc(100% - 712px);
		background-color: rgb(32,0,1);
		color: white;
		padding: 50vh 0 0 0;
	}

	.text {
		position: relative;
		width: 100%;
		height: 300vh;
		color: rgb(220,113,43);
		text-align: center;
		padding: 4em 0.5em 0.5em 0.5em;
		box-sizing: border-box;
		pointer-events: none;
	}

	.menu {
		justify-content: center;
		display:grid;
		row-gap: 10px;
		column-gap: 10px;
		grid-template-columns: repeat(auto-fit, min(100%, 375px));
	}

	body {
		font-family: 'Roboto', 'Flow Circular', sans-serif;
	}

	h1
	{
		font-size: 1.75em;
		color: #ff3e00;
		font-weight: 100;
		font-family: 'Roboto', "Cinzel", 'Flow Circular',  sans-serif;
		font-display: swap;
		
		margin-left: 25px;
	}

	main 
	{
		position: absolute;
		max-width: none;
		width: 100%;
		z-index: 1;
		/* height: 54px; */
		/*line-height: 54px;*/
		height:6px;
		line-height: 6px;
		box-shadow: 0 0px 4px 4px #0c0c0c;

		background-color: #1c1c1c;
	}

	nav 
	{
		position: fixed;
		width: 100%;
		height: 64px;
		z-index: 2;
		line-height: 64px;
		filter: drop-shadow(0 5px 5px black);
		background-color: #1c1c1c;
	}

	nav div.box
	{
		float:right;
		font-size: 1.25em;
		font-weight: 600;
	}

	nav a
	{
		line-height: 54px;
		margin-right:25px;
	}

	nav div.title
	{
		float:left;
		display: flex;
		flex-direction: row;
	}

	nav div span{
		line-height: 56px;
	}

	.photos {
		width: 100%;
		display: grid;
		grid-gap: 0px;
		grid-row-gap: 0px;
	}

	.flexi {
		display: flex;
		flex-direction: column;
	}

	img {
		width: 100%;
		margin: 0;
		border: none;
	}

    textarea
    {
        background-color: #3c3c3c;
        color: white;
		width:100%;
    }

	button
	{
		background-color: #2c2c2c;
        color: white;
	}


	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>
